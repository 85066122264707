import Footer from "@/components/common/Footer";
import { AnimatePresence, motion } from "framer-motion";
import { useEffect, useState } from "react";
import CancerVETLogo from "@/assets/CancerVet_logo.svg?react";

function App() {
  const [currentTextIndex, setCurrentTextIndex] = useState(0);

  const texts = [
    "질량 분석 기술을 사용하는",
    "혈액 내 대사체 바이오마커를 사용하는",
    "인공지능으로 학습된 모델을 사용하는",
    "수의사와 보호자를 모두 만족시킬 수 있는",
  ];

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentTextIndex((prevIndex) => (prevIndex + 1) % texts.length);
    }, 3000);

    return () => clearInterval(interval);
  }, []);

  return (
    <div className="relative flex flex-col min-h-screen z-10">
      <main className="flex-grow max-w-7xl mx-auto py-6 sm:px-6 lg:px-8 z-10">
        <div className="flex items-center justify-center flex-col mt-10">
          <CancerVETLogo className="h-[120px] w-[450px] mr-2 mb-4" />
          <p className="mt-7  sm:tracking-tight text-[38px] font-semibold text-center">
            Early tumor diagnosis AI platform based on metabolite biomarkers for
            pet
          </p>
        </div>

        <div className="flex mt-14 relative items-center justify-center overflow-hidden rounded-lg bg-transparent h-48">
          <div className="grid grid-cols-2 gap-4 items-center">
            <div>
              <AnimatePresence mode="wait">
                <motion.p
                  key={currentTextIndex}
                  initial={{ opacity: 0, y: 20 }}
                  animate={{ opacity: 1, y: 0 }}
                  exit={{ opacity: 0, y: -20 }}
                  transition={{ duration: 0.5 }}
                  className="text-3xl text-black"
                >
                  {texts[currentTextIndex]}
                </motion.p>
              </AnimatePresence>
            </div>
            <p className="text-3xl text-black">
              반려동물 조기 종양 예측 플랫폼입니다.
            </p>
          </div>
        </div>

        <div className="mt-[100px] grid grid-cols-3 gap-4">
          <div className="col-span-2 bg-white overflow-hidden shadow-md rounded-lg">
            <div className="px-4 py-5 sm:p-6">
              <h3 className="text-lg font-medium text-gray-900">공지사항</h3>
              <p className="mt-1 text-sm text-gray-500">
                최신 업데이트 및 공지사항을 확인하세요.
              </p>
            </div>
          </div>
          <div className="bg-white overflow-hidden shadow-md rounded-lg">
            <div className="px-4 py-5 sm:p-6">
              <h3 className="text-lg font-medium text-gray-900">서비스 문의</h3>
              <p className="mt-1 text-sm text-gray-500">
                궁금한 점이 있으시면 언제든 문의해주세요.
              </p>
            </div>
          </div>
        </div>
      </main>
      <Footer />
    </div>
  );
}

export default App;
